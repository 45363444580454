import { Modal, Button } from "@cimpress/react-components";
import ReactJson from "react-json-view";

export default function PreviewModal({
  setModalOpen,
  modalOpen,
  productLink,
  productionDelay,
}) {
  return (
    <>
      <Modal
        show={modalOpen}
        onRequestHide={() => setModalOpen(false)}
        title="Preview Production Delay"
        closeButton={true}
        footer={<Button onClick={() => setModalOpen(false)}>Close</Button>}
        size="lg"
      >
        <div>
          <div className="section-header" style={{ paddingTop: 0 }}>
            Product Link Details
          </div>
          <ReactJson src={productLink} collapsed name={false} />
          {productionDelay ? (
            <>
              <div className="section-header">Production Delay Details</div>
              <ReactJson src={productionDelay?.value} collapsed name={false} />
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
}
