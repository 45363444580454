export const Types = {
  SHOW_SNACKBAR: "SHOW_SNACKBAR",
  HIDE_SNACKBAR: "HIDE_SNACKBAR",
};

export const hideSnackbar = (payload) => ({
  type: Types.HIDE_SNACKBAR,
  payload,
});

export const showSnackbar = (payload) => ({
  type: Types.SHOW_SNACKBAR,
  payload,
});
