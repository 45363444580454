import { centralizedAuth } from "@cimpress/simple-auth-wrapper";

export const CLIENT_ID = "XSIYeH0hBkM0Ty1Wx4F1aBfYPzxU6Ebh";

const auth = new centralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: "/",
});

export default auth;
