import { getData } from "../services/http";

const productionDelay = {
  name: "Production Delay Resource",
  label: "Production Delay",
  relatedResourceType: "productionDelay",
  api: process.env.REACT_APP_PRODUCTION_DELAY_API,
  relatedResourceBaseHref: process.env.REACT_APP_PRODUCTION_DELAY_BASE_URL,
  getResource: async () => {
    const {
      _embedded: { item },
    } = await getData(process.env.REACT_APP_PRODUCTION_DELAY_BASE_URL);
    const formattedItems = item.map((delay) => ({
      value: delay,
      label: `${delay.id} (${delay.name})`,
    }));
    return formattedItems;
  },
};

export { productionDelay };
