import "./Shell.css";
import { Link } from "react-router-dom";
import Header from "@cimpress-technology/react-platform-header";
import CssLoader from "@cimpress/react-components/lib/CssLoader";
import auth from "../../../services/auth";

function Shell({ children }) {
  return (
    <CssLoader>
      <Header
        responsive
        accessToken={auth.getAccessToken()}
        appTitle={
          <Link className="navTitle" to="/">
            Production Delay
          </Link>
        }
        profile={auth.getProfile()}
        onLogInClicked={() => auth.login()}
        onLogOutClicked={() => auth.logout("/")}
        isLoggedIn={auth.isLoggedIn()}
      />
      <div className="root-child">{children}</div>
    </CssLoader>
  );
}

export default Shell;
