import "./ResourceLinker.css";
import auth from "../../../services/auth";
import { TextField, Button, Select } from "@cimpress/react-components";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ProductLinkerUtilities } from "@cimpress-technology/react-product-experience-rules";
import PreviewModal from "../../modals/PreviewModal";
import { showSnackbar } from "../../../actions/snackbar";
import { SNACKBAR_STATUS } from "../../../constants/snackbarStatus";
import Loading from "../../common/Loading";
import CreateDelayLink from "../../common/CreateDelayLink";

function ResourceLinker({
  productLink,
  setIsLoading,
  resetPage,
  showSnackbar,
  setSuccessModalData,
  disableButtons,
  resourceConfig,
}) {
  const [productionDelay, setProductionDelay] = useState();
  const [productionDelayList, setProductionDelayList] = useState([]);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [linkerLoading, setLinkerLoading] = useState(false);

  const fetchProductionDelayList = useCallback(async () => {
    try {
      setProductionDelayList(await resourceConfig.getResource());
    } catch (e) {
      showSnackbar({
        status: SNACKBAR_STATUS.DANGER,
        message: "Error fetching list of production delays!",
      });
      console.error(e);
    }
  }, [resourceConfig, showSnackbar]);

  useEffect(() => {
    fetchProductionDelayList();
  }, [showSnackbar, resourceConfig, fetchProductionDelayList]);

  const refreshHandler = async () => {
    setLinkerLoading(true);
    await fetchProductionDelayList();
    setLinkerLoading(false);
  };

  const updateProductionDelay = (obj) => {
    setProductionDelay(obj);
  };

  const addProductionDelayHandler = async () => {
    try {
      setIsLoading(true);
      await ProductLinkerUtilities.addRelatedResources({
        productLinkId: productLink.productLinkHref.slice(
          process.env.REACT_APP_PRODUCT_LINK_BASE_URL.length
        ),
        relatedResources: [
          {
            relatedResourceId: productionDelay?.value?.id,
            relatedResourceHref:
              resourceConfig.relatedResourceBaseHref +
              productionDelay?.value?.id,
            relatedResourceType: resourceConfig.relatedResourceType,
          },
        ],
        accessToken: auth.getAccessToken(),
      });
      resetPage();
      setIsLoading(false);
      setSuccessModalData({
        open: true,
        productLink: productLink.productLinkHref,
      });
    } catch (e) {
      showSnackbar({
        status: SNACKBAR_STATUS.DANGER,
        message: "Error updating product link!",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {productLink?.productLinkName || productionDelay ? (
        <div className="linker-function-card">
          {linkerLoading ? (
            <div style={{ paddingTop: "50px" }}>
              <Loading />
            </div>
          ) : (
            <>
              <div className="section-header">Link Delays With Product</div>
              <TextField
                name="Product Link Name"
                label="Product Link Name"
                value={productLink.productLinkName}
                disabled
              />
              <TextField
                name="Product Link Href"
                label="Product Link Href"
                value={productLink.productLinkHref}
                disabled
              />
              <Select
                isClearable
                label={resourceConfig.label}
                value={productionDelay}
                options={productionDelayList}
                onChange={updateProductionDelay}
              />

              <CreateDelayLink
                resourceConfig={resourceConfig}
                refreshHandler={refreshHandler}
              />

              <Button
                onClick={addProductionDelayHandler}
                className="linker-btn"
                variant="primary"
                disabled={!productionDelay || disableButtons}
              >
                Save
              </Button>
              <Button
                onClick={() => setPreviewModalOpen(true)}
                className="linker-btn"
                id="preview-btn"
                disabled={disableButtons}
              >
                Preview
              </Button>

              <PreviewModal
                setModalOpen={setPreviewModalOpen}
                modalOpen={previewModalOpen}
                productLink={productLink}
                productionDelay={productionDelay}
              />
            </>
          )}
        </div>
      ) : null}
    </>
  );
}

export default connect(null, { showSnackbar })(ResourceLinker);
