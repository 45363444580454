import auth from "./auth";

const getData = async (url) => {
  const headers = {
    Authorization: `Bearer ${auth.getAccessToken()}`,
  };
  const response = await fetch(url, { headers });
  return await response.json();
};

export { getData };
