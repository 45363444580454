import "./ProductGroup.css";
import auth from "../../services/auth";
import { ProductLinker } from "@cimpress-technology/react-product-experience-rules";
import ResourceLinker from "./ResourceLinker/ResourceLinker";
import { useState } from "react";
import { connect } from "react-redux";
import Loading from "../common/Loading";
import { Snackbar } from "@cimpress/react-components";
import SuccessModal from "../modals/SuccessModal";
import { hideSnackbar } from "../../actions/snackbar";
import { relatedResourceConfig } from "../../resourceType";

function ProductGroup({ snackbarData, hideSnackbar }) {
  const [productLink, setProductLink] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [successModalData, setSuccessModalData] = useState({ open: false });
  const [disableResourceLinkerButtons, setDisableResourceLinkerButtons] =
    useState(false);

  const onUseProductLink = (node) => {
    setProductLink(node);
    setDisableResourceLinkerButtons(false);
  };

  const resetProductGroupComponent = () => {
    setProductLink({});
  };

  const onProductLinkChangeSelection = () => {
    setDisableResourceLinkerButtons(true);
    setProductLink({});
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="container-fluid">
          <div className="product-group-card">
            <div className="section-header">Specify Products</div>
            <ProductLinker
              account={{
                id: auth.getProfile()["https://claims.cimpress.io/account"],
              }}
              accessToken={auth.getAccessToken()}
              authProfile={auth.getProfile()}
              onUseProductLink={onUseProductLink}
              onChangeSelectionClick={onProductLinkChangeSelection}
            />
          </div>
          {productLink ? (
            <ResourceLinker
              productLink={productLink}
              setIsLoading={setIsLoading}
              resetPage={resetProductGroupComponent}
              setSuccessModalData={setSuccessModalData}
              disableButtons={disableResourceLinkerButtons}
              resourceConfig={relatedResourceConfig.productionDelay}
            />
          ) : null}
          <Snackbar
            show={snackbarData.show}
            status={snackbarData.status}
            delay={3000}
            onHideSnackbar={hideSnackbar}
          >
            {snackbarData.message}
          </Snackbar>
          <SuccessModal
            setModalData={setSuccessModalData}
            modalData={successModalData}
          />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    snackbarData: state.snackbar,
  };
};

export default connect(mapStateToProps, { hideSnackbar })(ProductGroup);
