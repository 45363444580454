import "./SuccessModal.css";
import { Modal, Button } from "@cimpress/react-components";
import { success } from "@cimpress/react-components/lib/colors";
import { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { getData } from "../../services/http";
import Loading from "../common/Loading";
import IconCheckCircleAlt from "@cimpress-technology/react-streamline-icons/lib/IconCheckCircleAlt";

export default function SuccessModal({ setModalData, modalData }) {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [productLinkData, setProductLinkData] = useState();

  useEffect(() => {
    const fetchProductLinkData = async () => {
      setIsModalLoading(true);
      const data = await getData(modalData.productLink);
      setProductLinkData(data);
      setIsModalLoading(false);
    };
    modalData.productLink && fetchProductLinkData();
  }, [modalData]);

  return (
    <>
      <Modal
        status="success"
        show={modalData.open}
        onRequestHide={() => setModalData({ ...modalData, open: false })}
        title={
          <div className="success-modal-title">
            Product Link updated successfully
            <div>
              <IconCheckCircleAlt color={success.darker} size="2x" />
            </div>
          </div>
        }
        closeButton={true}
        footer={
          <Button onClick={() => setModalData({ ...modalData, open: false })}>
            Close
          </Button>
        }
        size="lg"
      >
        <>
          {isModalLoading ? (
            <Loading />
          ) : (
            <div>
              <div className="section-header" style={{ paddingTop: 0 }}>
                Product Link Details
              </div>
              <ReactJson src={productLinkData} collapsed name={false} />
            </div>
          )}
        </>
      </Modal>
    </>
  );
}
