import "./CreateDelayLink.css";
import IconInformationCircle from "@cimpress-technology/react-streamline-icons/lib/IconInformationCircle";
import { Tooltip } from "@cimpress/react-components";

const CreateDelayLink = ({ refreshHandler, resourceConfig }) => (
  <>
    <a
      className="linker-resource-api-help"
      href={resourceConfig.api}
      target="_blank"
      rel="noreferrer"
    >
      Create a new {resourceConfig.name}
    </a>{" "}
    |
    <Tooltip
      direction="right"
      contents="Refresh to view newly created resources"
    >
      <a href onClick={refreshHandler} className="linker-resource-refresh">
        Refresh&nbsp;
        <IconInformationCircle size="lg" weight="fill" />
      </a>
    </Tooltip>
  </>
);

export default CreateDelayLink;
