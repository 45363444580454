import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import auth from "./services/auth";
import Loading from "./components/common/Loading";
import PageNotFound from "./components/common/PageNotFound";
import Shell from "./components/common/Shell/Shell";
import ProductGroup from "./components/ProductGroup/ProductGroup";

auth.on("tokenExpired", () =>
  auth.login({ nextUri: window.location.pathname, forceLogin: true })
);

function App() {
  const [authenticating, setAuthenticating] = useState(false);
  const [authenticationError, setAuthenticationError] = useState();

  useEffect(() => {
    if (!auth.isLoggedIn()) {
      setAuthenticating(true);
      auth
        .ensureAuthentication({
          nextUri: window.location.pathname + window.location.search,
        })
        .then(() => setAuthenticating(false))
        .catch((err) => setAuthenticationError(err));
    }
  }, []);

  return (
    <div>
      {authenticating ? (
        <Loading />
      ) : auth.isLoggedIn() ? (
        <Router basename={process.env.REACT_APP_ROUTER_BASENAME || ""}>
          <Shell>
            <Switch>
              <Route exact path="/(index.html)?" component={ProductGroup} />
              <Route component={PageNotFound} />
            </Switch>
          </Shell>
        </Router>
      ) : authenticationError ? (
        <div>Unexpected error encountered. {authenticationError.message}</div>
      ) : null}
    </div>
  );
}

export default App;
